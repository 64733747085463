<template>
  <div class="vx-row">
    <div :set="orderdata = this.ordersList" class="vx-col w-full mb-base">
      <vx-card class="mb-5">
        <div class="flex flex-wrap justify-end">
          <vs-button @click="launchEmailDialog" class="mr-4 sm:mb-0 mb-2">Send Email Confirmation</vs-button>
          <vs-button @click="showSendToBackOfficeDialog=true" class="mr-4 sm:mb-0 mb-2">Send To Backoffice</vs-button>
        </div>
      </vx-card>
      <vx-card class="mb-5">
        <div class="table-grid-item">
          <div class="label-field">
            <h4>Order ID:</h4>            
          </div>
          <div class="text-field">
            <span v-html="prefixedOrderId"></span>
          </div>
         <div class="label-field">
            <h4>Order Status:</h4>            
          </div>
          <div class="text-field">
            <v-select
              :clearable="false"
              :value="selectedOrderStatus"
              @input="setOrderStatus"
              :options="orderStatusOptions"
            />
            <!-- <span v-html="this.ordersList.orderStatus"></span> -->
          </div>
        </div>
        <div class="table-grid-item">
          <div class="label-field">
            <h4>Customer Language:</h4>            
          </div>
          <div class="text-field">
            <span v-html="this.ordersList.orderLangCode"></span>
          </div>
          <div class="label-field">
              <h4>Order Total:</h4>            
            </div>
            <div class="text-field">
              <span v-html="this.ordersList.orderTotal"></span>
            </div>
        </div>
        <div class="table-grid-item">
          <div class="label-field">
            <h4>Customer IP:</h4>            
          </div>
          <div class="text-field">
            <a :href="'https://whatismyipaddress.com/ip/'+ this.ordersList.orderIpAddress" target="_blank">
            <span v-html="this.ordersList.orderIpAddress"></span>
            </a>
          </div>
             <div class="label-field">
            <h4>Created:</h4>            
          </div>
          <div class="text-field">
            <span v-html="this.ordersList.createdDate"></span>
          </div>
        </div>
        <div class="table-grid-item">
          <div class="label-field">
            <h4>Booking Agent</h4>            
          </div>
          <div class="text-field">
            <!-- <span v-html="this.ordersList.agentName"></span> -->
            <v-select
              class = "w-4/5"
              :clearable="false"
              :value="selectedBookingAgent"
              @input="setBookingAgent"
              :options="sortedBookingAgentOptions"
            />
          </div>
          <div class="label-field">
            <h4>Marketing Sources:</h4>            
          </div>
          <div class="text-field multi-line">
            <span v-for="(item, index) in this.ordersList.orderSourceList" v-html="item.sourceName+' : '+item.sourceValue" :key="index" ></span>           
          </div>
        </div>
      </vx-card>
       <vx-card class="mb-5">
        <div class="flex flex-wrap justify-end">
          <vs-button @click="showEditOrderDialog=true" type="border" size="small" class="mr-4 sm:mb-0 mb-2">Edit</vs-button>
        </div>
        <div class="table-grid-item">
          <div class="label-field">
            <h4>Customer ID:</h4>            
          </div>
          <div class="text-field d-flex" v-if="ordersList.customerId">     
            <span>{{this.ordersList.customerId}}</span>
            <span class="text-link">
              <a :href="'/applications/'+ applicationId +'/customers/'+ ordersList.customerId +'/view'">
                {{'&nbsp;(' +'View in CRM'+ ')'}}
              </a>
            </span>
          </div>
          <div v-else class="text-field d-flex"></div>
          <div class="label-field">
            <h4>Internal Reference:</h4>            
          </div>
          <div class="text-field">
            <span v-if="ordersList.orderRef" v-html="ordersList.orderRef"></span>
            <span v-else>N/A</span>
          </div>
        </div>
        <div class="table-grid-item">
          <div class="label-field">
            <h4>Customer Name:</h4>            
          </div>
          <div class="text-field multi-line">
            <span v-html="this.ordersList.orderCustomer==null?'':this.ordersList.orderCustomer.customerName"></span>
          </div>

          <div v-if="ordersList.specialRequest" class="label-field">
            <h4>Special Request:</h4>            
          </div>
          <div v-if="ordersList.specialRequest" class="text-field">
            <span v-html="ordersList.specialRequest"></span>
          </div>

        </div>
        <div class="table-grid-item-col-2">
          <div class="label-field">
            <h4>Customer Email:</h4>            
          </div>
          <div class="text-field multi-line">
            <span v-html="this.ordersList.orderCustomer==null?'':this.ordersList.orderCustomer.customerEmail"></span>
          </div>
        </div>
        <div class="table-grid-item-col-2">
          <div class="label-field">
            <h4>Customer Tel:</h4>            
          </div>
          <div class="text-field multi-line">
           <span v-html="(this.ordersList.orderCustomer==null||this.ordersList.orderCustomer.customerTel.telephoneNumber=='')?'':this.ordersList.orderCustomer.customerTel.telephoneType+': ' +this.ordersList.orderCustomer.customerTel.telephonePrefix+' '+this.ordersList.orderCustomer.customerTel.telephoneNumber"></span>
          </div>
        </div>
        <div class="table-grid-item-col-2">
          <div class="label-field">
            <h4>Customer Address:</h4>            
          </div>
          <div class="text-field multi-line">
            <span v-html="(this.ordersList.orderCustomer==null||this.ordersList.orderCustomer.customerAddress==null)?'':this.ordersList.orderCustomer.customerAddress.addressName"></span>
            <span v-html="(this.ordersList.orderCustomer==null||this.ordersList.orderCustomer.customerAddress==null)?'':this.ordersList.orderCustomer.customerAddress.addressLine1"></span>
            <span v-html="(this.ordersList.orderCustomer==null||this.ordersList.orderCustomer.customerAddress==null)?'':this.ordersList.orderCustomer.customerAddress.addressLine2"></span>
            <span v-html="(this.ordersList.orderCustomer==null||this.ordersList.orderCustomer.customerAddress==null)?'':this.ordersList.orderCustomer.customerAddress.addressCity"></span>
            <span v-html="(this.ordersList.orderCustomer==null||this.ordersList.orderCustomer.customerAddress==null)?'':this.ordersList.orderCustomer.customerAddress.addressState"></span>
            <span v-html="(this.ordersList.orderCustomer==null||this.ordersList.orderCustomer.customerAddress==null)?'':this.ordersList.orderCustomer.customerAddress.addressPostalCode"></span>
            <span v-html="(this.ordersList.orderCustomer==null||this.ordersList.orderCustomer.customerAddress==null)?'':this.ordersList.orderCustomer.customerAddress.addressCountryCode"></span>
          </div>
        </div>
      </vx-card>
      <vx-card class="mb-5 order-details">    
        
        <ag-grid-vue
          ref="agGridTable"
          :components="cellRendererComponents"
          class="ag-theme-material w-100 my-4"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="productList"
          :gridOptions="gridOptions"
          :enableCellTextSelection=true   
          domLayout="autoHeight"
          id="order-detail-grid"
          @first-data-rendered="onFirstDataRendered"
          @grid-size-changed="onGridSizeChanged"
          :context="context"
        >
        </ag-grid-vue>
      </vx-card>

      <vx-card v-if="this.ordersList && this.ordersList.orderDeposit" class="mb-5 order-details">
        <div class="vx-col w-full"><h4 class="mb-4">Deposit Breakdown</h4></div>
        <order-deposit :depositData="this.ordersList.orderDeposit"/>
      </vx-card>

      <vx-card class="mb-5">
        <order-payment-grid v-if="orderPaymentsList && orderPaymentsList.length > 0" :paymentItems="orderPaymentsList" />
        <div v-else class="text-center">No payments made</div>
        
        <div class="vx-row">
          <div class="vx-col w-1/2 mt-3">
            <div v-if="orderPaymentsList && orderPaymentsList.length > 0" class="vx-row mt-2">
              <h5 class="vx-col w-2/5 font-semibold">Balance Remaining:</h5>
              <div class="vx-col w-3/5" style="font-size:16px;font-weight:400">
                <span>{{this.ordersList.orderBalanceRemaining + " " + this.ordersList.orderCurrencyCode}}</span>
              </div>
            </div>
          </div>
          <div class="vx-col w-1/2">
            <div class="flex flex-wrap justify-end" v-if="this.ordersList.orderBalanceRemaining > 0">
              <vs-button @click="openAddPaymentPopup" type="border" color="success">Add Payment</vs-button>
            </div>
          </div>
        </div>

        <div v-if="orderVoucherCodes && orderVoucherCodes.length > 0" class="label-field">
          <h5 class="pb-0">Voucher Codes</h5>            
        </div>
        <voucher-codes v-if="orderVoucherCodes && orderVoucherCodes.length > 0" :voucherCodes="orderVoucherCodes" />

      </vx-card>
      <vx-card :set="itemdata = JSON.parse(item.itemData)[0]" v-for="(item,index) in this.ordersList.orderDetailsList" class="mb-5" :key="index">
        <flight-order-item v-if="item.productId =='1'" :orderData="ordersList" :orderItem="item" />
        <accomodation-item v-if="item.productId =='2'" :orderItem="item" :itemdata="itemdata" :orderdata="ordersList"/>
        <package-holidays-item v-if="item.productId =='3'" :orderData="ordersList" :itemdata="itemdata" :orderItem="item" />
        <tickets-attractions-item v-if="item.productId =='4'" :orderData="ordersList" :itemdata="itemdata" :orderItem="item" /> 
        <taxis-transfer-item v-if="item.productId =='5'" :orderData="ordersList" :itemdata="itemdata" :orderItem="item" /> 
        <airport-extras-item v-if="item.productId =='6'" :orderData="ordersList" :itemdata="itemdata" :orderItem="item" /> 
        <insurance-item v-if="item.productId =='7'" :orderData="ordersList" :orderItem="item" />
        <car-rental-item v-if="item.productId =='8'" :orderData="ordersList" :itemdata="itemdata" :orderItem="item" /> 
      </vx-card>
    </div>

    <send-email-confirmation-dialog 
      v-if="showSendEmailDialog"
      :applicationId="applicationId"
      :orderId="orderId"
      :customerEmail="confirmationEmailAddress"
      @onSuccessSend="showSendEmailDialog=false"
      @onCancel="showSendEmailDialog=false"
    />

    <send-to-backoffice-dialog 
      v-if="showSendToBackOfficeDialog"
      :applicationId="applicationId"
      :orderId="orderId"
      @onSuccessSend="showSendToBackOfficeDialog=false"
      @onCancel="showSendToBackOfficeDialog=false"
    />

    <edit-order-dialog 
      v-if="showEditOrderDialog"
      :applicationId="applicationId"
      :orderId="orderId"
      :ordersList="ordersList"
      @onSaveSuccess="$router.go()"
      @onCancel="showEditOrderDialog=false"
    />

    <cancel-order-item-dialog 
      v-if="showCancelOrderItemDialog"
      :applicationId="applicationId"
      :orderId="orderId"
      :orderItemData="cancelOrderItemData"
      @onCancelOrderItemSuccess="$router.go()"
      @onCancel="showCancelOrderItemDialog=false"
    />

    <edit-order-item-dialog 
      v-if="showEditOrderItemDialog"
      :itemId="editOrderItemId"
      :applicationId="applicationId"
      :orderId="orderId"
      :productList="productList"
      :currencyOptions="currencyOptions"
      @onEditOrderItemSuccess="$router.go()"
      @onCancel="showEditOrderItemDialog=false"
    />

    <add-payment-dialog 
      v-if="showAddPaymentDialog"
      :applicationId="applicationId"
      :orderId="orderId"
      :orderCurrencyCode="this.ordersList.orderCurrencyCode"
      :balanceRemaining="this.ordersList.orderBalanceRemaining"
      @onCancel="showAddPaymentDialog=false"
      @onAddPaymentSuccess="handleAddPaymentSuccess"
    />
  
  </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import TravioModal from '@/components/travio-pro/TravioModal.vue'
import CellRendererIntegrationsActions from './cell-renderers/CellRendererIntegrationsActions.vue'
import CellRendererOrderItemActions from './cell-renderers/CellRendererOrderItemActions.vue'
import OrderPaymentGrid from './OrderPaymentGrid.vue'
import Vue from "vue"
import VoucherCodes from './components/VoucherCodes.vue'
import FlightArrivalBarChart from '../company-dashboard/widgets/flight-arrival/FlightArrivalBarChart.vue';
import TravelifyDisplayField from '@/components/travio-pro/TravelifyDisplayField.vue'

import FlightOrderItem from './components/FlightOrderItem.vue'
import AccomodationItem from './components/AccomodationItem.vue'
import InsuranceItem from './components/InsuranceItem.vue'
import TicketsAttractionsItem from './components/TicketsAttractionsItem.vue'
import CarRentalItem from './components/CarRentalItem.vue'
import PackageHolidaysItem from './components/PackageHolidaysItem.vue'
import TaxisTransferItem from './components/TaxisTransferItem.vue'
import AirportExtrasItem from './components/AirportExtrasItem.vue'

import SendEmailConfirmationDialog from './components/SendEmailConfirmationDialog.vue'
import SendToBackofficeDialog from './components/SendToBackofficeDialog.vue'
import EditOrderDialog from './components/EditOrderDialog.vue'
import EditOrderItemDialog from './components/EditOrderItemDialog.vue'
import CancelOrderItemDialog from './components/CancelOrderItemDialog.vue'
import AddPaymentDialog from './components/AddPaymentDialog.vue'

import OrderDeposit from './components/OrderDeposit.vue'
export default {  
  props: {
    applicationId: { required: true }
  },
  components: {
    AgGridVue,
    TravioModal,
    CellRendererIntegrationsActions,
    CellRendererOrderItemActions,
    OrderPaymentGrid,
    VoucherCodes,
    FlightArrivalBarChart,
    FlightOrderItem,
    AccomodationItem,
    InsuranceItem,
    PackageHolidaysItem,
    EditOrderDialog,
    EditOrderItemDialog,
    CancelOrderItemDialog,
    SendEmailConfirmationDialog,
    SendToBackofficeDialog,
    TicketsAttractionsItem,
    CarRentalItem,
    TaxisTransferItem,
    AirportExtrasItem,
    OrderDeposit,
    AddPaymentDialog,
    TravelifyDisplayField,
  },
  data () {
    return {
      ordersList: [],
      productList: [],
      orderPaymentsList: null,
      agentList: [],
      statusList: [],
      gridOptions: null,
      gridApi: null,
      date:null,
      agent:null,
      orderId:null,
      orderIdPrefix:null,
      orderStatus:null,
      searchtext:null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererIntegrationsActions,
        CellRendererOrderItemActions
      },
      searchQuery: '',
      showDeleteRolePopup: false,
      selectedReplacementRole: {},
      selectedOrderStatus: {},
      orderStatusOptions: [
        { code: 1, label: 'Basket' }, 
        { code: 2, label: 'Processing'},
        { code: 3, label: 'Confirmed'},
        { code: 4, label: 'Confirmed with failure' },
        { code: 5, label: 'Cancelled'},
        { code: 6, label: 'Cancelled Partial'},
      ],
      currencyOptions: [],
      bookingAgentOptions: [],
      selectedBookingAgent: {},
      showEditOrderDialog: false,
      orderVoucherCodes: [],
      showEditOrderItemDialog: false,
      editableOrderItemData: {},
      showSendEmailDialog: false,
      showSendToBackOfficeDialog: false,
      confirmationEmailAddress: null,
      showCancelOrderItemDialog: false,
      showAddPaymentDialog: false,
      editOrderItemId: null,
      cancelOrderItemId: null,
      cancelOrderItemData: null,
    }
  },
  computed: {
    prefixedOrderId() {
      return (this.ordersList && this.ordersList.orderIdPrefix + this.ordersList.orderId ) || ""
    },
    phoneTypeOptions () {
      return this.$store.state.lookups.phoneTypeOptions
    },
    titleOptions () {
      return this.$store.state.lookups.titleOptions
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    sortedBookingAgentOptions () {
      if (!this.bookingAgentOptions) {
        return this.bookingAgentOptions;
      }
      // Put current user at the top of dropdown option
      let data = this.bookingAgentOptions.sort((first, second) => (first.label.toLowerCase() > second.label.toLowerCase()) ? 1 : -1)
      let currentUser = this.bookingAgentOptions.find(x => x.code == this.activeUserInfo.userId)
      
      if(!currentUser) return data

      data = data.filter(item => item.code != this.activeUserInfo.userId);
      data.unshift(currentUser)
      return data
    }
  },
  async created () {
 
     //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }

    this.$vs.loading()
    try {
      const orderDetailsPromise = this.$http.get(`api/OrderManage/apps/${this.applicationId}/${this.$route.params.orderId}`);  
      const bookingAgentsPromise = this.$http.get(`api/OrderManage/orderAgents/${this.$store.state.ActiveCompanyId}`)
      const [response, bookingAgentsResponse] = await Promise.all([orderDetailsPromise, bookingAgentsPromise])

      this.ordersList = response.data;
      this.productList=response.data.orderDetailsList;
      this.orderId=response.data.orderId;  
      this.orderPaymentsList = response.data.orderPaymentList;  
      this.orderVoucherCodes = response.data.orderVoucherCodeList;
      this.selectedOrderStatus = this.orderStatusOptions.find(x => x.code == response.data.orderStatusId) || {};
      this.currencyOptions = response.data.currencyOptions
      this.bookingAgentOptions = bookingAgentsResponse.data 
        && bookingAgentsResponse.data.map(x => ({ code: x.userId, label: x.fullName}))
      this.selectedBookingAgent = this.bookingAgentOptions.find(x => x.code == response.data.agentId) || {};

    } catch (error) {
      this.$_notifyFailure(error)
    }
    this.$vs.loading.close()
    
  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [   
      { field: 'orderItemId', hide: true },
      { field: 'itemStatus', hide: true },
      { headerName: 'Type', field: 'productName', width: 120, filter: true },
      { headerName: 'Supplier', field: 'supplierName', width: 130, filter: true },
      { headerName: 'Departure/Duration', field: 'itemStartDate', width: 180, filter: true },
      { headerName: 'Added To Basket', field: 'itemCreatedDate', width: 160, filter: true },
      { headerName: 'Supplier Price', field: 'itemSupplierPrice', width: 130, filter: true },
      { headerName: 'Selling Price', field: 'itemSellingPrice',width: 120 },
      { headerName: 'Booking Reference', field: 'itemBookingRef', sortable: true, width: 140} ,
      {
        headerName: "Actions",
        sortable: false,
        width:70,
        cellRendererFramework: Vue.extend(CellRendererOrderItemActions)
      }
    ]

    this.defaultColDef = {
      flex: 1,
      sortable: true,
      resizable: true,
      autoHeight: true,
      suppressMenu: true
    }

    this.context = { componentParent: this }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.gridApi.sizeColumnsToFit();
  },
  
  methods: {
    async setOrderStatus(value) {
      this.$vs.loading()
      try {
        await this.$http.post(`api/OrderManage/apps/${this.applicationId}/${this.$route.params.orderId}/orderStatus`, { orderStatusId: value.code })
        this.selectedOrderStatus = value;
        this.$_notifySuccess('Order status successfully updated')
      } catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()
    },
    async setBookingAgent(value) {
      this.$vs.loading()
      try {
        await this.$http.post(`api/OrderManage/apps/${this.applicationId}/${this.$route.params.orderId}/bookingAgent`, { bookingAgentId: value.code })
        this.selectedBookingAgent = value;
        this.$_notifySuccess('Booking agent successfully updated')
      } catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()
    },
    launchEmailDialog() {
      this.showSendEmailDialog = true
      this.confirmationEmailAddress = this.ordersList.orderCustomer.customerEmail
    },
    async sendEmailConfirmation() {
      this.$vs.loading()
      this.showSendEmailDialog = false
      try {
        await this.$http.post(`api/OrderManage/apps/${this.applicationId}/emailConfirmation`, { 
          orderId: this.orderId,
          emailAddress: this.confirmationEmailAddress
        })
        this.$_notifySuccess('Successfully sent email confirmation')
      } catch (error) {
        this.$_notifyFailure(error)
      }
      
      this.$vs.loading.close()
    },
    async openAddPaymentPopup() {
      this.showAddPaymentDialog = true
    },
    // Called by cell renderer from order item grid
    launchOrderItemEditor(itemId) {
      this.editOrderItemId = itemId
      this.showEditOrderItemDialog = true
    },
    async launchCancelOrderItemDialog(itemData) {
      this.cancelOrderItemData = itemData
      this.showCancelOrderItemDialog = true
    },
    async handleAddPaymentSuccess(){
      this.showAddPaymentDialog=false

      this.$vs.loading()
      try {
        const response = await this.$http.get(`api/OrderManage/apps/${this.applicationId}/${this.$route.params.orderId}`)
        this.orderPaymentsList = response.data.orderPaymentList;
        this.ordersList = response.data;
        this.gridApi && this.gridApi.sizeColumnsToFit();
        this.$_notifySuccess('Successfully added order payment.');
      } catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged(params) {
     
    },
  }
}
</script>

<style>
  
  .vx-row.align-items-end {
    align-items: flex-end;
  }
  .flatpickr-calendar.multiMonth{
        width: 640px !important;
  }
  .table-grid-item {
    display: grid;
    grid-template-columns: 20% 30% 20% 30%;
  }
  .table-grid-item .label-field h4,
  .table-grid-item-col-2 .label-field h4,
  .table-grid-item-col-5 .label-field h4,
  .table-grid-item-col-1 .label-field h4,
  .table-grid-item-col-1 .label-field,
  .table-grid-item-col-3 .label-field h4  {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
  }
  .table-grid-item .text-field span,
  .table-grid-item-col-2 .text-field span,
  .table-grid-item-col-5 .text-field span{
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    word-break: break-word;
  }
  .table-grid-item-col-1{
    display: grid;
    grid-template-columns: auto;
    margin-bottom:10px;
  }
  .table-grid-item-col-1:last-child{
    margin:0;
  }
  .table-grid-item-col-1-pd{
    display: grid;
    grid-template-columns: auto;    
    padding-left: 25px;
  }
  .table-grid-item-col-2{
    display: grid;
    grid-template-columns: 20% 80%;
  }
  .label-field .table-grid-item-col-2 {
    display: grid;
    grid-template-columns: 40% 60%;
  }
  .table-grid-item-col-3{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .table-grid-item-col-4.order-list{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .table-grid-item-col-2.order-list{
    display: grid;
    grid-template-columns: 1fr 2fr;
    border: 1px solid #d7d7dd;
    padding: 10px;
  }
  .custom-table-section{
    border: 1px solid #d7d7dd;
    padding: 10px;
  }
  .table-grid-item-col-5{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
  }
  .text-field.multi-line {
    display: grid;
    grid-template-columns: auto;
  }
  .img-field img{
    width:100%;
    height:100%;
    border-radius: 5px;
  }  
  .order-details .ag-grid-table{
    height:320px;
  }
  .table-grid-item-col-2-pd {
    padding-left: 20px;
  }
  .img-field img {
    width: 180px;
    height: auto;
    margin: 0 auto;
}
.guests-header-item,.guests-body-item {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    border: 1px solid #d7d7dd;
    padding: 10px;
}
.guest-details {
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
}
.guest-body-detail-item{
    font-size:16px;
    font-weight: 400;
    line-height: normal;
}
.img-field h4 {
    text-align: center;
}
.guest-section {
    padding-top: 10px;
}
.guests-table-layout {
    padding-top: 10px;
}
.rating-star svg{
  width:15px;
  height:15px;
}
h4{
  word-break: break-all;
}
   .ag-cell, .ag-header-cell-text {
    text-overflow: clip !important;
    overflow: visible !important;
    white-space: normal !important;
    line-height: normal !important;
    word-break: break-word;
}
.ag-cell, .ag-header-cell{
  padding:10px 5px !important;
}
.text-field.d-flex {
    display: flex;
    flex-wrap: nowrap;
}


.order-item-strike-through {
  text-decoration: line-through; 
}

</style>
